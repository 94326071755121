// 商品路由
const name = '设置'
import Layout from '@/view/layout' // 主框架

export default {
  path: '/shopadmin/shopsetting',
  component: Layout,
  children: [
    {
      path: 'baseexport',
      name: `导出列表`,
      component: () => import('@/view/base/exportlog/index')
    }
  ]
}
